import { useEffect, useRef, useState } from "react";
import { GoogleCloudPlatformService, WorkflowUpdateHandler } from "../../services";
import { SubmitHandler, useForm } from "react-hook-form";
import { usePartnerStore } from "../../stores";
import { AuthorizationDisclosure, CardWithBackground } from "../../components";
import { UTILITIES_CONFIG, UTILITY_PROVIDER_SCHEMA, UtilityProps } from "../../configs/UtilitiesConfig";
import { useSearchParams } from "react-router-dom";
import { RDCStatusCodePage, StatusCode } from "..";
import { supabase } from "../../supabaseClient";
import mixpanel from "mixpanel-browser";
import { KeyIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { CALENDLY_DEMO_URL } from "../../constants";

export const LOGIN_ERROR_MESSAGE =
  "An error occurred while logging in. Please try again later or contact info@ecotrove.com.";

type UtilityForm = { username: string; password: string };

/**
 * Props to pass to a `ConnectSingleUtilityPage`.
 */
export interface ConnectSingleUtilityPageProps {
  /** The GCP client to inject. */
  client: GoogleCloudPlatformService;
}

/**
 * Page that allows a user to upload single utility credentials.
 * @returns a React component.
 */
export const ConnectSingleUtilityPage: React.FC<ConnectSingleUtilityPageProps> = ({ client }) => {
  // Page variables
  const { partner } = usePartnerStore();
  const [searchParams] = useSearchParams();
  const [pageStatus, setPageStatus] = useState<{ code: StatusCode; additionalInfo?: string }>();
  const pageAlreadySetup = useRef(false);
  const [emailState, setEmailState] = useState<string>();

  // Utility form variables
  const loginForm = useForm<UtilityForm>();
  const [utilityProps, setUtilityProps] = useState<UtilityProps>();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState<() => void>();
  const [formSubmitStatus, setFormSubmitStatus] = useState<{
    message: string;
    type: "loading" | "error";
  }>();
  const [showManualQuoteMessage, setShowManualQuoteMessage] = useState(false);

  /**
   * Perform setup operations such as validating parameters, redirecting to pilot page if applicable, etc.
   */
  useEffect(() => {
    const doSetup = async () => {
      try {
        // Check whether this effect has already run; if so, short circuit execution to prevent unwanted side effects
        if (pageAlreadySetup.current) return;
        else pageAlreadySetup.current = true;

        // Check that provider path search exists
        const providerParam = searchParams.get("Provider");
        if (!providerParam) {
          setPageStatus({ code: 400, additionalInfo: "missing provider information" });
          return;
        }

        // If provider is "other" redirect to a demo
        if (providerParam.toLowerCase() === "other") {
          window.location.replace(CALENDLY_DEMO_URL);
          return;
        }

        // Otherwise, check that the provider is valid
        const parseProviderResult = UTILITY_PROVIDER_SCHEMA.safeParse(providerParam);
        if (!parseProviderResult.success) {
          setPageStatus({ code: 400, additionalInfo: "invalid provider name" });
          return;
        }
        const provider = parseProviderResult.data;

        // Check that email search param exists
        const email = searchParams.get("Email")?.trim().toLowerCase();
        if (!email) {
          setPageStatus({ code: 400, additionalInfo: "missing email information" });
          return;
        }
        setEmailState(email);

        // Check that email is allowed to get a quote
        // TODO: add this check back in once we have two users in on 3/31
        // if (!(await Utils.canGetQuote(email))) {
        //   setPageStatus({ code: 403, additionalInfo: "not authorized to receive a quote" });
        //   return;
        // }

        // Params are valid at this point, track the page visit on Mixpanel
        mixpanel.track("View utility login form", { provider });

        // Set the utility config that should be displayed in the login form
        setUtilityProps(UTILITIES_CONFIG[provider]);
      } catch (error) {
        setPageStatus({ code: 500 });
      }
    };
    doSetup();

    // On unmount, remove subscription to GCP updates
    return () => {
      if (unsubscribe) unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update multiple supbase tables with GCP information.
   * @param userId the GCP user ID.
   */
  const updateSupabaseTables = async (userId: string): Promise<void> => {
    // Add the GCP ID to user-profiles
    const { error: userProfileError } = await supabase
      .from("user-profiles")
      .upsert(
        { email: emailState, gcp_user_id: userId, terms_complete: true, referred_by_partner: partner },
        { onConflict: "email" },
      );
    // Add a new record to submitted-credentials which will kick off an email to info@ecotrove.com
    const { error: submittedCredentialsError } = await supabase
      .from("submitted-credentials")
      .upsert({ email: emailState, gcp_user_id: userId, utility_providers: [utilityProps?.provider] });
    // Add a new record to terms-and-conditions
    const { error: termsError } = await supabase
      .from("terms-and-conditions")
      .insert({ email: emailState, privacy_checked: true, terms_checked: true });
    if (userProfileError) throw new Error(userProfileError.message);
    if (submittedCredentialsError) throw new Error(submittedCredentialsError.message);
    if (termsError) throw new Error(termsError.message);
  };

  /** Handle submit events to the login form. */
  const handleSubmit: SubmitHandler<UtilityForm> = async (data) => {
    // This is just a precaution, these will always exist before the submit is shown
    if (!utilityProps || !emailState) return;
    try {
      setDisableSubmit(true);
      setFormSubmitStatus({ message: "Authorizing...", type: "loading" });
      // Unsubscribe from any previous listeners before creating a new one
      if (unsubscribe) unsubscribe();
      const executeResult = await client.executeWorkflow({
        name: "store-user-password-workflow",
        input: {
          ecotrove_email: emailState,
          user_id: client.getUserId(emailState),
          credential_id: client.getCredentialId(utilityProps.provider, data.username),
          utility_provider: utilityProps.provider,
          username: data.username,
          password: data.password,
        },
        onUpdate: handleStatusUpdates,
      });
      setUnsubscribe(() => executeResult);
    } catch (error) {
      setFormSubmitStatus({ message: LOGIN_ERROR_MESSAGE, type: "error" });
      setDisableSubmit(false);
    }
  };

  /** Handle update events from GCP. */
  const handleStatusUpdates: WorkflowUpdateHandler = async (snapshot) => {
    try {
      const document = snapshot.data();
      if (!document || !document.status || !emailState) return;
      const userId = client.getUserId(emailState);
      switch (document.status) {
        case "EXECUTION_STARTED":
          setFormSubmitStatus({ message: "Verifying credentials. This may take a few moments...", type: "loading" });
          break;

        case "INVALID_CREDENTIALS":
          setFormSubmitStatus({ message: "Invalid username and/or password.", type: "error" });
          setDisableSubmit(false);
          break;

        case "MANUAL_QUOTE":
          await updateSupabaseTables(userId);
          setShowManualQuoteMessage(true);
          break;

        case "SUCCESS":
          if (!document.data || !document.data.user_id || !document.data.url) throw new Error("Invalid GCP response");
          await updateSupabaseTables(userId);

          // If a business quote should be explicitly allowed, modify the URL to reflect so
          const url = new URL(document.data.url);
          const state = url.searchParams.get("state");
          if (state) {
            const preventAuthExchange = "0"; // Allow auth exchange to occur
            const allowBusinessQuoteState = partner ? "1" : "0";
            const updatedState = preventAuthExchange + allowBusinessQuoteState + state.substring(2);
            url.searchParams.set("state", updatedState);
          }

          // Include everything but the origin in the redirect URL to maintain the origin in dev vs. prod
          const redirectUrl = url.pathname + url.search + url.hash;
          window.location.assign(redirectUrl);
          return;
        default:
          throw new Error(`Unrecognized status: ${document.status}`);
      }
    } catch (error) {
      setFormSubmitStatus({ message: LOGIN_ERROR_MESSAGE, type: "error" });
      setDisableSubmit(false);
    }
  };

  return pageStatus ? (
    <RDCStatusCodePage code={pageStatus.code} additionalInfo={pageStatus.additionalInfo} />
  ) : !utilityProps ? (
    <></>
  ) : (
    <CardWithBackground className="flex flex-col items-center text-center text-neutral-content">
      <div className="flex flex-col items-center justify-start gap-default">
        <img className="h-16" src={utilityProps.logoPath} alt={`${utilityProps.fullName} logo`} />
        <div className="flex flex-col gap-default font-semibold">
          <h1 className="text-lg text-base-content lg:text-xl">Your {utilityProps.fullName} Account Details</h1>
          <h2 className="text-sm lg:text-base">
            Log in to your {utilityProps.abbreviatedName} account to authorize EcoTrove to view your historical energy
            usage patterns.
          </h2>
        </div>
      </div>
      {showManualQuoteMessage && (
        <div className="w-full space-y-6 rounded-3xl bg-success p-5">
          <p data-testid="manual-quote-message" className="text-success-content">
            Your submission has been received. We will reach out with next steps within 2 business days.
          </p>
          <a className="daisy-btn daisy-btn-primary w-full" href="/">
            Return Home
          </a>
        </div>
      )}
      {!showManualQuoteMessage && (
        <>
          <form
            className="flex w-full flex-col justify-center gap-6"
            onSubmit={loginForm.handleSubmit((data) =>
              handleSubmit({ ...data, username: data.username.toLowerCase() }),
            )}
          >
            {/* Username */}
            <label className="text-start">
              <p className="ml-1 flex items-center gap-1">
                <UserCircleIcon className="hero-icon hero-icon-sm" />
                Username (may be your email)
              </p>
              <input
                autoComplete="off"
                className="daisy-input daisy-input-bordered w-full aria-invalid:daisy-input-error aria-invalid:text-error"
                type="text"
                placeholder="Enter your username or email"
                aria-invalid={Boolean(loginForm.formState.errors.username)}
                disabled={disableSubmit}
                {...loginForm.register("username", { required: true })}
              />
            </label>
            {/* Password */}
            <label className="text-start">
              <p className="ml-1 flex items-center gap-1">
                <KeyIcon className="hero-icon hero-icon-sm" />
                Password
              </p>
              <input
                autoComplete="off"
                className="daisy-input daisy-input-bordered w-full aria-invalid:daisy-input-error aria-invalid:text-error"
                type="password"
                placeholder="Enter your password"
                aria-invalid={Boolean(loginForm.formState.errors.password)}
                disabled={disableSubmit}
                {...loginForm.register("password", { required: true })}
              />
            </label>
            {/* Submit button */}
            <button
              data-testid="submit"
              className="daisy-btn daisy-btn-primary w-full"
              type="submit"
              disabled={disableSubmit}
            >
              Authorize
            </button>
          </form>
          {/* Form status message */}
          {formSubmitStatus && (
            <div
              data-testid="submit-status"
              className={`flex gap-default ${formSubmitStatus.type === "error" ? "text-error" : "text-base-content"}`}
            >
              {formSubmitStatus.type === "loading" && <div className="daisy-loading daisy-loading-md" />}
              {formSubmitStatus.message}
            </div>
          )}
          {/* Forgot username/password */}
          {(utilityProps.resetUsernameUrl || utilityProps.resetPasswordUrl) && (
            <div>
              <span>Forgot </span>
              {utilityProps.resetUsernameUrl && (
                <a
                  className="daisy-link daisy-link-primary"
                  href={utilityProps.resetUsernameUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  username
                </a>
              )}
              {utilityProps.resetUsernameUrl && utilityProps.resetPasswordUrl && <span> or </span>}
              {utilityProps.resetPasswordUrl && (
                <a
                  className="daisy-link daisy-link-primary"
                  href={utilityProps.resetPasswordUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  password
                </a>
              )}
              <span>?</span>
            </div>
          )}
          <AuthorizationDisclosure shareMyDataUrl={utilityProps.shareMyDataUrl} />
        </>
      )}
    </CardWithBackground>
  );
};
