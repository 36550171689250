import { Route, Routes, Navigate } from "react-router-dom";
import TermsAndConditions from "./legacy/TermsAndConditions";
import mixpanel from "mixpanel-browser";
import { RDCAlertBanners, RDCScrollToTopProvider, RDCSupportIcon } from "./components";
import {
  RDCCustomizeQuotePage,
  RDCLoadDocusignPage,
  RDCPgeOAuthRedirectPage,
  RDCShowQuotePage,
  RDCThanksForReauthorizingPage,
  RDCReferPage,
  RDCLoginPage,
  RDCCreateAccountPage,
  RDCUpdatePasswordPage,
  RDCConfirmEmailPage,
  RDCUserDashboardPage,
  RDCAccountSettingsPage,
  RDCStatusCodePage,
  RDCResidentialQuotePage,
  RDCUserQuestionsPage,
  CheckParameterPage,
  ForceReloadPage,
  ConnectSingleUtilityPage,
} from "./pages";
import {
  CUSTOMIZE_QUOTE_ROUTE,
  HOW_IT_WORKS_ROUTE,
  PAYMENT_SUCCESS_ROUTE,
  PGE_OAUTH_REDIRECT_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  PRIVACY_POLICY_ROUTE,
  SHOW_QUOTE_ROUTE,
  BLOGS_ROUTE,
  ABOUT_US_ROUTE,
  THANKS_FOR_REAUTHORIZING_ROUTE,
  REFER_ROUTE,
  LOGIN_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  UPDATE_PASSWORD_ROUTE,
  CONFIRM_EMAIL_ROUTE,
  USER_DASHBOARD_ROUTE,
  ACCOUNT_SETTINGS_ROUTE,
  SUPPORT_ROUTE,
  STATUS_403_ROUTE,
  USER_QUESTIONS_ROUTE,
  STATUS_400_ROUTE,
  PARTNER_ROUTE,
  CONNECT_UTILITY_ROUTE,
} from "./constants";
import "./App.css";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import Hotjar from "@hotjar/browser";
import { GoogleCloudPlatformService } from "./services";

const App = () => {
  const [isIframe, setIsIframe] = useState<boolean>(false);

  // Initialize Mixpanel
  const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
  if (!mixpanelToken) {
    console.error("No token found");
  } else {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  // Initialize Google Analytics
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  if (gaId) {
    ReactGA.initialize(gaId);
  }

  // Initialize HotJar
  const hotjarSiteId = Number(process.env.REACT_APP_HOTJAR_SITE_ID);
  if (hotjarSiteId) {
    const hotjarVersion = 6;
    Hotjar.init(hotjarSiteId, hotjarVersion);
  }

  // Initialize GCP client
  const gcpClient = new GoogleCloudPlatformService();

  // Check if App is being displayed as part of an iframe
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("iframe") === "true") {
      setIsIframe(true);
    }
  }, []);

  return (
    <RDCScrollToTopProvider>
      {/* ======== STATIC COMPONENTS ============================================================================== */}
      <>
        <RDCAlertBanners />
        {!isIframe && <RDCSupportIcon />}
      </>
      <Routes>
        {/* ======== WEBFLOW (force reload these pages to "escape" client-side routing) =========================== */}
        <Route path={"/"} element={<ForceReloadPage />} />
        <Route path={HOW_IT_WORKS_ROUTE} element={<ForceReloadPage />} />
        <Route path={BLOGS_ROUTE} element={<ForceReloadPage />} />
        <Route path={`${BLOGS_ROUTE}/:blogId`} element={<ForceReloadPage />} />
        <Route path={TERMS_OF_SERVICE_ROUTE} element={<ForceReloadPage />} />
        <Route path={PRIVACY_POLICY_ROUTE} element={<ForceReloadPage />} />
        <Route path={ABOUT_US_ROUTE} element={<ForceReloadPage />} />
        <Route path={SUPPORT_ROUTE} element={<ForceReloadPage />} />
        <Route path={`${SHOW_QUOTE_ROUTE}/business`} element={<ForceReloadPage />} />
        <Route path={`${SHOW_QUOTE_ROUTE}/solar`} element={<ForceReloadPage />} />

        {/* ======== REACT ======================================================================================== */}
        <Route path={CONNECT_UTILITY_ROUTE} element={<ConnectSingleUtilityPage client={gcpClient} />} />
        <Route path={PGE_OAUTH_REDIRECT_ROUTE} element={<RDCPgeOAuthRedirectPage />} />
        <Route path={CUSTOMIZE_QUOTE_ROUTE} element={<RDCCustomizeQuotePage />} />
        <Route path={PAYMENT_SUCCESS_ROUTE} element={<RDCLoadDocusignPage />} />
        <Route path={SHOW_QUOTE_ROUTE} element={<RDCShowQuotePage />} />
        <Route path={`${SHOW_QUOTE_ROUTE}/residential`} element={<RDCResidentialQuotePage />} />
        <Route path={THANKS_FOR_REAUTHORIZING_ROUTE} element={<RDCThanksForReauthorizingPage />} />
        <Route path={REFER_ROUTE} element={<RDCReferPage />} />
        <Route path={LOGIN_ROUTE} element={<RDCLoginPage />} />
        <Route path={CREATE_ACCOUNT_ROUTE} element={<RDCCreateAccountPage />} />
        <Route path={UPDATE_PASSWORD_ROUTE} element={<RDCUpdatePasswordPage />} />
        <Route path={`${CONFIRM_EMAIL_ROUTE}/:email`} element={<RDCConfirmEmailPage />} />
        <Route path={USER_DASHBOARD_ROUTE} element={<RDCUserDashboardPage />} />
        <Route path={ACCOUNT_SETTINGS_ROUTE} element={<RDCAccountSettingsPage />} />
        <Route path={USER_QUESTIONS_ROUTE} element={<RDCUserQuestionsPage />} />

        {/* ======== PARAMETER PAGES ============================================================================== */}
        <Route path={`${REFER_ROUTE}/:referrer`} element={<CheckParameterPage parameter="referrer" />} />
        <Route path={`${PARTNER_ROUTE}/:partner`} element={<CheckParameterPage parameter="partner" />} />

        {/* ======== STATUS CODES ================================================================================= */}
        <Route path={STATUS_400_ROUTE} element={<RDCStatusCodePage code={400} />} />
        <Route path={STATUS_403_ROUTE} element={<RDCStatusCodePage code={403} />} />
        <Route path="*" element={<RDCStatusCodePage code={404} />} />

        {/* ======== MANUAL REDIRECTS TO HOME ===================================================================== */}
        <Route path="/ecotrove_fixed_price_utility_bill" element={<Navigate to="/" replace />} />
        <Route path="/energy_subscription_quote" element={<Navigate to="/" replace />} />
        <Route path="/.ea" element={<Navigate to="/" replace />} />

        {/* ======== Legacy routes (to be deprecated) ============================================================= */}
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </RDCScrollToTopProvider>
  );
};

export default App;
